import { useEffect, useState } from 'react'
import { Box, Typography } from '@mui/material'
import { useParams } from 'react-router-dom'


import { getLecturesByTopic } from '../controllers/education'
import LecturesListChip from './LecturesListChip'
import Loader from './Loader'

const LecturesList = () => {
    const { topicId } = useParams()
    const [lectures, setLectures] = useState([])
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        getLecturesByTopic(topicId, setLectures, setLoading)
    }, [topicId])
    return (
        loading ? <Loader /> :
            <Box display="flex" flexDirection="column" alignItems="center" paddingX={2}>
                {lectures.length ?
                    lectures.map(lecture => <LecturesListChip key={lecture._id} lecture={lecture} />)
                    :
                    <Typography variant="body2" paddingTop={2}>
                        No lectures available for this topic yet.
                    </Typography>}
            </Box>
    )
}

export default LecturesList