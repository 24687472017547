import React from 'react'
import { Route, Routes, useNavigate } from "react-router-dom";
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import { Fab } from '@mui/material';

import TopicRouter from './Topic';
import CourseComponent from '../components/Course';

import { App } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';

const Course = () => {
    const nav = useNavigate();
    App.addListener('backButton', () => goBack)
    const goBack = () => nav(-1);
    return (
        <>
            {Capacitor.isNativePlatform() ?
                <Fab variant="extended" size="small" color="secondary" sx={{ position: "absolute", bottom: "20px", right: "20px" }} onClick={goBack}>
                    <KeyboardDoubleArrowLeftIcon />
                    Back
                </Fab>
                : <></>
            }
            <Routes>
                <Route path='/' element={<CourseComponent />} />
                <Route path='/topics/:topicId/*' element={<TopicRouter />} />
            </Routes>
        </>
    )
}

export default Course