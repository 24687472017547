import { Box, Typography, Card, CardContent, CardMedia, CardActionArea } from '@mui/material'
import { useNavigate } from 'react-router-dom'

const CoursesListChip = ({ course }) => {
    const nav = useNavigate()
    const navigate = () => {
        nav(`courses/${course._id}`)
    }
    return (
        <Card sx={{ width: "100%", marginY: 2, backgroundColor: 'background.paper' }} onClick={navigate}>
            <CardActionArea>
                <CardMedia
                    component="img"
                    image={course.image || "http://via.placeholder.com/640x260"}
                    alt="placeholder for course image"
                />
                <CardContent>
                    <Box>
                        <Typography variant='body2'>{course.name}</Typography>
                    </Box>
                </CardContent>
            </CardActionArea>
        </Card>
    )
}

export default CoursesListChip