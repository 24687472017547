import { Route, Routes } from "react-router-dom";
import CourseRouter from "./Course";

import CoursesList from "../components/CoursesList";
import NavBarAndHeader from "../components/NavBarAndHeader";
import { Box, Toolbar } from "@mui/material";

const Home = () => {
  return <>
    <NavBarAndHeader />
    <Box sx={{ overflowY: "auto", height: "100%", bottom: 0 }} >
      <Routes>
        <Route path="/" element={<CoursesList />} />
        <Route path="/courses/:courseId/*" element={<CourseRouter />} />
      </Routes>
      <Toolbar />
    </Box >
  </>
};

export default Home;
