import { useEffect, useState } from 'react'
import { Box } from '@mui/material'

import { getLectureById } from '../controllers/education'
import MyPlyrVideo from './VideoContainer'
import Loader from './Loader';

const LecturePage = ({ lectureId }) => {
    const [lecture, setLecture] = useState(null)
    useEffect(() => {
        getLectureById(lectureId, setLecture)
    }, [lectureId])
    return (
        <Box display="flex" flexDirection="column" alignItems="center" paddingX={2} height="600px">
            {lecture ?
                <Box width="100vw">
                    {/* <Box width="100vw" pt={2}> */}
                    <MyPlyrVideo source={lecture.video} name={lecture.name} />
                    <iframe title='Notes PDF' src={`${lecture.notes}?embedded=true`} width={"100%"} frameBorder="0" />
                </Box>
                :
                <Loader />
            }
        </Box>
    )
}

export default LecturePage