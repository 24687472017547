import { Box } from '@mui/system'
import React, { useEffect, useState } from 'react'

import { getAllCourses } from "../controllers/education"
import CoursesListChip from './CoursesListChip'
import Loader from './Loader'

const CoursesList = () => {
    const [courses, setCourses] = useState([])
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        getAllCourses(setCourses, setLoading)
    }, [])
    return loading ? <Loader /> : <Box display="flex" flexDirection="column" alignItems="center" paddingX={2}> {courses.map(course => <CoursesListChip key={course._id} course={course} />)}</Box>
}

export default CoursesList