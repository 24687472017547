import axios from "axios"
// eslint-disable-next-line

import Cookies from "js-cookie"

import { HOST } from "./constants"
import { toasts } from "./toastControllers"

const login = async ({ email, password, nav }) => {
    axios.post(HOST + "/auth/user/login", { email, password }).then(({ data }) => {
        Cookies.set("accessToken", data.accessToken, {
            expires: 100,
        })
        setTimeout(() => nav("/home"), 200)
    }).catch((err) => {
        toasts.error(err?.response?.data?.message || "Invalid email/password")
    })
}

const validateToken = async (setLoading, nav) => {
    axios.get(HOST + "/auth/validate", {
        headers: {
            authorization: `Bearer ${Cookies.get("accessToken")}`
        }
    })
        .catch(err => nav("/login"))
        .finally(() => setLoading(false)
        )
}

const handleLogout = (nav) => {
    Cookies.remove('accessToken')
    nav("/login");
}

export { login, validateToken, handleLogout }