import { useState, useEffect } from "react";
import { TextField, Typography } from "@mui/material";
import {
    IonContent,
    IonPage,
    IonIcon,
} from "@ionic/react";
import { useNavigate } from "react-router-dom";

import "./Signup.css";

import Logo from "../assets/markaz_logo-cropped.svg";

import { arrowForwardOutline } from "ionicons/icons";
import { create, verify } from "../controllers/signup";
import { Link } from "react-router-dom";


const Signup = () => {
    const nav = useNavigate();

    const [credentials, setCredentials] = useState({
        email: "",
        name: "",
        phone: "",
        password: ""
    });

    const [pwdConfirm, setPwdConfirm] = useState("")
    const [otpSent, setOtpSent] = useState(false);


    const [loginStep, setLoginStep] = useState("input-invalid");

    useEffect(() => {
        const validateCredentials = () => {
            var mailformat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
            var passFormat = /^[a-zA-Z0-9!@#$%^&*]{8,}$/;
            var phoneFormat = /^\d{10}$/;

            if (!credentials.email.match(mailformat))
                return setLoginStep("input-invalid");
            if (!credentials.password.match(passFormat))
                return setLoginStep("input-invalid");
            if (!credentials.phone.match(phoneFormat))
                return setLoginStep("input-invalid");
            if (credentials.password !== pwdConfirm)
                return setLoginStep("input-invalid");
            setLoginStep("input-valid");
        };
        validateCredentials();
    }, [credentials, pwdConfirm]);

    const handleCredentialsChange = (e) => {
        setCredentials((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    };

    const handleCreate = () => {
        loginStep === "input-valid" && create({ ...credentials, setOtpSent });
    };

    const [otp, setOtp] = useState("")

    const handleVerify = () => {
        loginStep === "input-valid" && verify({ email: credentials.email, phone: credentials.phone, otp }, nav);
    };

    return (
        <IonPage id="home-page">
            <IonContent fullscreen>
                <div className="main-page-holder">
                    <div className="logo-login-page">
                        <img src={Logo} alt="" className="logo-image" />
                    </div>
                    <div className="login-container">
                        <Typography variant="body2">Already have an account? <Link to={"/login"}>Log In Here</Link> </Typography>
                        <div className="input-fields-container">
                            <TextField
                                name="name"
                                value={credentials.name}
                                onChange={handleCredentialsChange}
                                className="input-field"
                                margin="dense"
                                label="Full Name"
                                disabled={otpSent}
                                fullWidth
                            />
                            <TextField
                                name="email"
                                value={credentials.email}
                                onChange={handleCredentialsChange}
                                className="input-field"
                                margin="dense"
                                label="Email"
                                disabled={otpSent}
                                fullWidth
                            />
                            <TextField
                                name="phone"
                                value={credentials.phone}
                                onChange={handleCredentialsChange}
                                className="input-field"
                                margin="dense"
                                label="Phone Number"
                                disabled={otpSent}
                                fullWidth
                            />
                            <TextField
                                name="password"
                                value={credentials.password}
                                onChange={handleCredentialsChange}
                                className="input-field"
                                margin="dense"
                                label="Password"
                                type="password"
                                disabled={otpSent}
                                fullWidth
                            />
                            <TextField
                                value={pwdConfirm}
                                onChange={(e) => setPwdConfirm(e.target.value)}
                                className="input-field"
                                margin="dense"
                                label="Confirm Password"
                                type="password"
                                disabled={otpSent}
                                fullWidth
                            />
                            {otpSent ? <TextField
                                value={otp}
                                onChange={(e) => setOtp(e.target.value)}
                                className="input-field"
                                margin="dense"
                                label="OTP"
                                fullWidth
                            /> : <></>}
                        </div>
                        <div
                            className={`login-button ${loginStep === "input-valid" ? "active" : "inactive"
                                }`}
                            onClick={otpSent ? handleVerify : handleCreate}
                        >
                            <div className="login-button-text">{otpSent ? "Verify OTP" : "Create Account"}</div>
                            <div className="login-button-arrow">
                                <IonIcon icon={arrowForwardOutline} size="large"></IonIcon>
                            </div>
                        </div>
                    </div>
                </div>
            </IonContent>
        </IonPage>
    );
};

export default Signup;