import { Route, Routes } from "react-router-dom";
import LectureRouter from './Lecture';
import LecturesList from '../components/LecturesList';

const Topic = () => {
    return (
        <Routes>
            <Route path='/' element={<LecturesList />} />
            <Route path='/lectures/:lectureId/*' element={<LectureRouter />} />
        </Routes>
    )
}

export default Topic