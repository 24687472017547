import Axios from "axios";
import Cookies from "js-cookie"
import { setupCache } from 'axios-cache-interceptor';

import { HOST } from "./constants";
import { toasts } from "./toastControllers";
let axios_cached = setupCache(Axios.create({ baseURL: HOST }), { ttl: 30 * 1000 });

const axios = () => {
    axios_cached.defaults.headers.common['Authorization'] = `Bearer ${Cookies.get("accessToken")}`
    return axios_cached
}

const getAllCourses = async (setCourses, setLoading) => {
    axios().get(`/user/courses`)
        .then(async ({ data }) => {
            setCourses(data.courses)
            setLoading(false)
        }).catch(err => {
            toasts.error("Something went wrong")
            setLoading(false)
        })
}

const getTopicsByCourse = async (id, setTopics, setAvailability, setCourseName, setLoading) => {
    axios().get(`/user/topics/${id}`, { headers: { Authorization: `Bearer ${Cookies.get("accessToken")}` } })
        .then(async ({ data }) => {
            setAvailability(data.available)
            setTopics(data.topics || [])
            setCourseName(data.name)
            setLoading(false)
        }).catch(err => {
            toasts.error("Something went wrong")
            setLoading(false)
        })
}

const getLecturesByTopic = async (id, setLectures, setLoading) => {
    axios().get(`/user/lectures/${id}`, { headers: { Authorization: `Bearer ${Cookies.get("accessToken")}` } })
        .then(async ({ data }) => {
            setLectures(data.lectures)
            setLoading(false)
        }).catch(err => {
            toasts.error("Something went wrong")
            setLoading(false)
        })
}

const getLectureById = async (id, setLecture) => {
    axios().get(`/user/lectures/lecture/${id}`).then(({ data }) => {
        setLecture(data)
    })
}

const requestCourse = async (courseId, setLoading) => {
    setLoading(true)
    axios().get(`/user/courses/request/${courseId}`)
        .then(({ data }) => {
            data.success
                ? toasts.success("Course request sent. Please wait while we get in touch with you.")
                : toasts.error("Something went wrong")
            setLoading(false)
        })
}

export { getAllCourses, getTopicsByCourse, getLecturesByTopic, getLectureById, requestCourse }