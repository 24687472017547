import { Typography, Card, CardContent, CardActionArea } from '@mui/material'
import { useNavigate } from 'react-router-dom'

const LecturesListChip = ({ lecture }) => {
    const nav = useNavigate()
    const navigate = () => {
        nav(`lectures/${lecture._id}`)
    }
    return (
        <Card sx={{ width: "100%", marginY: 2, backgroundColor: 'background.paper' }} onClick={navigate}>
            <CardActionArea>
                <CardContent>
                    <Typography variant='body2'>{lecture.name}</Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    )
}

export default LecturesListChip