import { useEffect, useState } from 'react'
import { Box, Button, Typography } from '@mui/material'


import { getTopicsByCourse, requestCourse } from '../controllers/education'
import TopicsListChip from './TopicsListChip'
import Loader from './Loader'

const TopicsList = ({ courseId }) => {
    const [topics, setTopics] = useState([])
    const [loading, setLoading] = useState(true)
    const [courseName, setCourseName] = useState("")

    const [isAvailable, setAvailability] = useState(true)

    useEffect(() => {
        getTopicsByCourse(courseId, setTopics, setAvailability, setCourseName, setLoading)
    }, [courseId])
    return loading
        ?
        <Loader /> :
        <Box display="flex" flexDirection="column" alignItems="center" paddingX={2} paddingTop={3}>
            {courseName.length ? <Typography variant="h6">{courseName}</Typography> : ""}
            {!isAvailable ? <CourseRequest {...{ courseId }} /> :
                topics.length ?
                    topics.map(topic => <TopicsListChip key={topic._id} topic={topic} />)
                    :
                    <Typography variant="body2" paddingTop={2}>
                        No topics available for this course yet.
                    </Typography>
            }
        </Box>
}

const CourseRequest = ({ courseId }) => {
    const [reqLoading, setReqLoading] = useState(false)
    return <Button variant="contained" sx={{ marginTop: 2 }} disabled={reqLoading} onClick={() => requestCourse(courseId, setReqLoading)}>{reqLoading ? <Loader /> : "Request this course"}</Button>
}

export default TopicsList