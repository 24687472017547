import React, { useEffect, useState } from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";

import Loader from "../components/Loader";
import Login from "../pages/Login";
import { validateToken } from "../controllers/auth";
import Home from "./Home";

import { createTheme, ThemeProvider } from "@mui/material";
import Signup from "../pages/Signup";

const theme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#f9f4d4',
    },
    secondary: {
      main: '#b1e1f7',
    },
    background: {
      paper: '#eee',
      default: '#fafafa',
    },
  },
})

const Index = () => {
  const nav = useNavigate();
  const handleEvent = () => {
    nav(-1);
  }
  useEffect(() => {
    window.addEventListener("popstate", handleEvent);
    return () => window.removeEventListener("popstate", handleEvent);
  });
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    validateToken(setLoading, nav);
    // eslint-disable-next-line
  }, []);

  return <ThemeProvider theme={theme}>
    {loading ? (
      <Loader />
    ) : (
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/home/*" element={<Home />} />
        <Route exact path="/" element={<Navigate to="/home" />} />
      </Routes>
    )}
  </ThemeProvider>
};

export default Index;
