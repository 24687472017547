import { useState, useEffect } from "react";
import { TextField, Typography } from "@mui/material";
import {
  IonContent,
  IonPage,
  IonIcon,
} from "@ionic/react";
import { useNavigate, Link } from "react-router-dom";

import "./Login.css";

import Logo from "../assets/markaz_logo-cropped.svg";

import { arrowForwardOutline } from "ionicons/icons";
import { login } from "../controllers/auth";
const Login = () => {
  const nav = useNavigate();
  const [credentials, setCredentials] = useState({
    email: "",
    password: "",
  });
  const [loginStep, setLoginStep] = useState("input-invalid");

  useEffect(() => {
    const validateCredentials = () => {
      var mailformat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
      var passFormat = /^[a-zA-Z0-9!@#$%^&*]{8,}$/;

      if (!credentials.email.match(mailformat))
        return setLoginStep("input-invalid");
      if (!credentials.password.match(passFormat))
        return setLoginStep("input-invalid");
      setLoginStep("input-valid");
    };
    validateCredentials();
  }, [credentials]);

  const handleCredentialsChange = (e) => {
    setCredentials((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleLogin = () => {
    loginStep === "input-valid" && login({ ...credentials, nav });
  };

  return (
    <IonPage id="home-page">
      <IonContent fullscreen>
        <div className="main-page-holder">
          <div className="logo-login-page">
            <img src={Logo} alt="" className="logo-image" />
          </div>
          <div className="login-container">
            <Typography variant="body2">Don't have an account yet? <Link to={"/signup"}>Sign Up Here</Link> </Typography>
            <div className="input-fields-container">
              <TextField
                name="email"
                value={credentials.email}
                onChange={handleCredentialsChange}
                className="input-field"
                margin="normal"
                label="Email"
                fullWidth
              />
              <TextField
                name="password"
                value={credentials.password}
                onChange={handleCredentialsChange}
                className="input-field"
                margin="normal"
                label="Password"
                type="password"
                fullWidth
              />
            </div>
            <div
              className={`login-button ${loginStep === "input-valid" ? "active" : "inactive"
                }`}
              onClick={handleLogin}
            >
              <div className="login-button-text">Log In</div>
              <div className="login-button-arrow">
                <IonIcon icon={arrowForwardOutline} size="large"></IonIcon>
              </div>
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Login;