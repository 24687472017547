import { toast } from 'react-toastify'

const settings = {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
}

export const toasts = {
    success: (message) => toast.success(message, settings),
    error: (message) => toast.error(message, settings),
    warning: (message) => toast.warn(message, settings),
    info: (message) => toast.info(message, settings),
}