import React from 'react'
import { useParams } from 'react-router-dom'

import TopicsList from './TopicsList'


const Course = () => {
    const { courseId } = useParams()
    return <TopicsList {...{ courseId }} />
}

export default Course