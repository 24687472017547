import axios from "axios"
// eslint-disable-next-line

import Cookies from "js-cookie"

import { HOST } from "./constants"
import { toasts } from "./toastControllers"

const create = async ({ email, password, phone, name, setOtpSent }) => {
    axios.post(HOST + "/signup", { email, password, phone, name }).then(({ data }) => {
        setTimeout(() => setOtpSent(true), 200)
        toasts.success("Please check your email inbox for OTP")
    }).catch((err) => {
        toasts.error(err?.response?.data?.message || "Invalid email/password")
    })
}

const verify = async (body, nav) => {
    axios.post(HOST + "/signup/verify", body)
        .then(({ data }) => {
            Cookies.set("accessToken", data.accessToken, {
                expires: 100,
            })
            nav("/home")
        })
        .catch(err => nav("/login"))
}

export { create, verify }